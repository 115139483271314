<template>
    <div class="match_full_detail">
        <div class="text-center" v-if="!is_data_ready">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else>
          <div v-if="tournaments.length > 0 && req_date">
            <!-- when no data available for selected date,showing upcoming/previous matches along with message-->
            {{$t('football.No match available')}} for {{$route.params.date}}
            <div style="color: white; text-align:center; background-color: #0C2251;padding:10px; margin: 10px 0">{{list_date | formatDateDay}}</div>
            <MatchCard :tournaments="tournaments"></MatchCard>
          </div>
          <div v-else-if="tournaments.length > 0 && !req_date">
            <!-- when data available for selected date, show matches only -->
            <MatchCard :tournaments="tournaments"></MatchCard>
          </div>
          <div v-else-if="tournaments.length == 0">
            <!-- when no data available for selected date and upcoming/previous matches show only message-->
            {{$t('football.No match available')}}
          </div>
        </div>
    </div>
</template>

<script>
    import MatchCard from './match-card';
    import moment from "moment";
    export default {
        name: "rugby-match-listing",
        metaInfo() {
            if (this.$store.state.siteSetting) {

                let ss = this.$store.state.siteSetting;
                var replacements = {
                    '%DATE%': (this.$route.params.date == moment(new Date()).format("YYYY-MM-DD")) ? 'Today' : this.$route.params.date,
                    '%TAB%': this.$getSafe(() => this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.substring(1)),
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_listing_meta_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_listing_meta_description,replacements));
                let keywords = this.$getSafe(() => ss.rugby_listing_meta_keywords);

                // my matches
                let routeName = this.$route.name.substring((this.$route.name.indexOf('.') + 1), this.$route.name.length);
                if (routeName == 'myMatches') {
                    title = this.$getSafe(() => ss.rugby_my_matches_meta_title);
                    description = this.$getSafe(() => rugby_my_matches_meta_description);
                    keywords = this.$getSafe(() => rugby_my_matches_meta_keywords);
                }

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
                };
            }
        },
        data() {
            return {
                tournaments: '',
                req_date: '',
                list_date: ''
            }
        },
        components: {
            MatchCard
        },
        watch: {
            '$route.params.date'() {
                this.getMatches();
            },
            '$route.params.type'() {
                this.getMatches();
            }
        },
        mounted() {
            this.getMatches();

        },
        serverPrefetch() {
            return this.getMatches();
        },
        /*        metaInfo() {
         var title = '';
         var description = '';
         var keywords = '';
         if (this.$route.name == 'myMatches') {
         keywords = title = 'Football Live Scores, Transfers and Fixtures - Kisma Sports';
         description = 'Football Live Scores from Every Football League, Live Transfers Updates and Fixtures of All the Football Games from Kisma Sports.'
         } else if (this.$route.params.type == 'all') {
         let date = '';
         if(this.$route.params.date ==  moment(new Date()).format("YYYY-MM-DD")) {
         date = 'Today'
         } else {
         date = this.$route.params.date;
         }
         keywords = title = 'All Football Live Scores ' + date;
         description = 'Full fixture list of all Live scores, real time, live football, fixtures and results for 2022-07-01 Including goalscorers, odds, live commentaries and statistics. For all major leagues including Premier League, Championship, Champions League, La Liga, Serie A, Bundesliga and Ligue 1 and 800+ soccer leagues.';
         } else if (this.$route.params.type == 'live') {
         keywords =title = 'Football Live Scores, Transfers and Fixtures - Kisma Sports';
         description = 'Football Live Scores from Every Football League, Live Transfers Updates and Fixtures of All the Football Games from Kisma Sports.';
         } else if (this.$route.params.type == 'finished') {
         keywords =title = 'Football Full-time Results For '+this.$route.params.date+' | Kisma';
         description = 'Football full-time results for '+this.$route.params.date+', With scorers, attendances, statistics, commentaries and photo galleries. For all major competitions including Premier League, Championship, Champions League, La Liga, Serie A, Bundesliga and Ligue 1.';
         } else if (this.$route.params.type == 'upcoming') {
         let date = '';
         if(this.$route.params.date ==  moment(new Date()).format("YYYY-MM-DD")) {
         date = 'Today'
         } else {
         date = this.$route.params.date;
         }
         keywords =title = 'Football Upcoming Fixtures '+date+' | Kisma';
         description = 'Football upcoming Fixtures today. With kickoff times and live odds for all major competitions including Premier League, Champions League, Championship, League One, League Two, La Liga, Serie A, Bundesliga and Ligue 1.';
         } else {
         keywords =title = 'Football Live Scores, Transfers and Fixtures - Kisma Sports';
         }
         return {
         title: title,
         meta: this.$getMetaTags(title, description, keywords),
         link:[
         {rel: 'canonical', href: 'https://kisma.6lgx.com'+this.$route.path}],
         }
         },
         */
        methods: {
            getMatches() {
                let url = ''
                let params = {date: this.$route.params.date, type: this.$route.params.type}
                if (this.$route.name == 'rugby.myMatches') {
                    url = this.$RUGBY_FAVOIRTE_MATCHES_API;
                    let favourites = this.$store.getters.rugbyFavouriteMatches.length > 0 ? this.$store.getters.rugbyFavouriteMatches : ['']
                    delete params.date;
                    delete params.type;
                    params.matches = favourites;
                } else {
                    url = this.$RUGBY_MATCHES_API;
                }
                this.is_data_ready = false;
                return this.$axios
                        .get(url, {params})
                        .then(response => {
                            if (response.data) {
                                this.tournaments = response.data.tournaments;
                                this.req_date = response.data.orignal_date;
                                this.list_date = response.data.list_date;
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => console.log(error))
            },
        }
    }
</script>
